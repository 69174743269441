<template>
  <div data-app>
    <div class="container-fluid">
      <!-- 1°first ROW -->
      <div class="row">
        <div class="col-md-12">
          <!-- 1°first Card -->
          <div class="card">
            <div class="card-header">
              Categoría
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span v-on="on" class="badge badge-primary badge-pill float-right sdw">
                    <i class="fas fa-archive text-white"></i>
                  </span>
                </template>
                <span>Detalle Categoría</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on}">
                  <span
                    v-on="on"
                    class="badge badge-warning badge-pill float-right sdw mr-1"
                    @click="dialog = true"
                  >
                    <i class="fas fa-edit text-dark"></i>
                  </span>
                </template>
                <span>Editar Categoría {{id}}</span>
              </v-tooltip>
            </div>
            <div class="card-body">
              <v-container>
                <!-- 1°second ROW -->
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <ul class="list-group mb-3" v-for="item in category" :key="item.id">
                      <h4 class="d-flex justify-content-between align-items-center mb-3">
                        <span class="text-muted">{{item.name}}</span>
                        <span class="badge badge-secondary badge-pill">ID: {{id}}</span>
                      </h4>
                      <li class="list-group-item d-flex justify-content-between lh-condensed">
                        <div>
                          <h6 class="my-0">Categoría: {{item.name}}</h6>
                          <small class="text-muted">Categoría Padre: {{item.paren_category}}.</small>
                        </div>
                        <div>
                          <span class="badge badge-secondary badge-pill float-right">
                            <i class="fas fa-archive"></i>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </v-col>
                </v-row>
                <!--Dialog--->
                <v-dialog v-model="dialog" width="800" persistent>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-card>
                          <v-card-title>Edítar Categoría</v-card-title>
                          <v-divider></v-divider>
                          <v-container>
                            <v-row>
                              <v-col cols="12" sm="2" md="2">
                                <v-text-field
                                  v-model="category[0].id"
                                  name="id"
                                  readonly="readonly"
                                  label="ID"
                                  filled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="9" md="9" offset-md="1">
                                <v-text-field v-model="category[0].name" label="Categoría"></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-select
                                  v-model="category[0].parent_category"
                                  :items="categories"
                                  item-text="name"
                                  item-value="id"
                                  label="Categorías"
                                ></v-select>
                              </v-col>
                            </v-row>
                          </v-container>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="#FFD700"
                              class="mx-2"
                              fab
                              dark
                              small
                              @click="editCategory()"
                            >
                              <v-icon color="#000000" dark>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn
                              class="mx-2"
                              fab
                              dark
                              small
                              color="#FF6347"
                              @click="dialog = false"
                            >
                              <v-icon dark>mdi-close</v-icon>
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-dialog>
                <!--End Dialog--->
              </v-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card:hover,
.sdw {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  color: black;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: ["id"],
  data: () => ({
    boolean: false,
    url: "http://localhost/apiagunsaoms/agunsa-api-oms/public/api/",
    dialog: false,
    category_name: "",
    category: [],
    categories: [],
    dateMsg:
      new Date().toISOString().substr(0, 10) +
      " " +
      new Date().toISOString().substr(11, 5),
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "➀ Categoría", route: "/inventario/categories" },
      {
        title: "➁ Detalle Categoría",
        route: `/inventario/categories/${this.id}`,
      },
    ]);
    this.fetchCategory();
    this.fetchCategories();
  },
  created() {
    //this.initialize();
  },
  methods: {
    initialize() {},
    close() {
      this.dialog = false;
    },
    editCategory() {
      var vm = this;

      console.log({
          name: vm.category[0].name,
          paren_category: vm.category[0].id,
        })
      this.axios({
        url: "inventory/categories/" + vm.category[0].id,
        method: "PUT",
        data: {
          name: vm.category[0].name,
          paren_category: vm.category[0].id,
        },
      })
        .then((response) => {
          vm.fetchCategory();
          vm.$bvToast.toast(
        `La categoria: ${this.category[0].name}, ha sido actualizada el ${this.dateMsg} exitosamente!`,
        {
          title: `Información`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-bottom-center",
        }
      );
        })
        .catch((error) => {
          console.log(error);
        });

      this.close();
    },
    fetchCategory() {
      var vm = this;
      this.axios({
        url: "inventory/categories/" + vm.id,
        method: "GET",
      })
        .then((response) => {
          console.log(response.data);
          vm.category.unshift(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchCategories() {
      var vm = this;
      this.axios({
        url: "inventory/categories",
        method: "GET",
      })
        .then((response) => {
          console.log(response.data.data);
          vm.categories = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>